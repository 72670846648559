import { Inject, Injectable, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Language } from '../enums/utils/languages.enum';
import { Subscription, take } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { BeUtilsService } from './BE/be-utils.service';
import { PrimeNGConfig } from 'primeng/api';
import {L10n, setCulture} from '@syncfusion/ej2-base';

export enum LanguageChangePriority {
  _1 = 1, // pri automatickem nastaveni / predvyplneni
  _2 = 2, // pri nastaveni pres url parametr
  _3 = 3, // pri manualnim nastaveni userem kliknutim
}

@Injectable({
  providedIn: 'root'
})
export class I18nService implements OnDestroy {

  private lsKey = 'LEKTORY_language';

  defaultLang = Language.EN;

  currentLangPriority: LanguageChangePriority = LanguageChangePriority._1;

  private subs: Subscription[] = [];

  constructor(
    private translate: TranslateService,
    private route: ActivatedRoute,
    @Inject(DOCUMENT) private document: Document,
    private beUtilsService: BeUtilsService,
    private primeNGConfig: PrimeNGConfig,
  ) {
    this.translate.addLangs([Language.CS, Language.EN]);
    this.translate.setDefaultLang(this.defaultLang);

    // see if there is a language stored in local storage
    const storedLang = localStorage.getItem(this.lsKey);
    if (storedLang) {
      this.useLanguage(storedLang as Language, LanguageChangePriority._1);
    } else {
      // get country code from ip-api.com

      this.beUtilsService.getLanguageByIp().pipe(
        take(1)
      ).subscribe({
        next: (res) => {
          if (res) {
            this.useLanguage(res, LanguageChangePriority._1);
          } else {
            this.setFallbackLanguage();
          }
        },
        error: (err) => {
          console.error(err);
          this.setFallbackLanguage();
        }
      });
    }

    // watch url parameters if there is a l parameter
    this.route.queryParams.subscribe(params => {
      if (params['l']) {
        this.useLanguage(params['l'], LanguageChangePriority._2);
      }
    });
  }

  private setStoredLanguage(language: Language) {
    localStorage.setItem(this.lsKey, language);
  }

  private setFallbackLanguage() {
    const browserLang = this.translate.getBrowserLang();
    const defaultLang = this.translate.defaultLang;

    this.useLanguage((browserLang || defaultLang) as Language, LanguageChangePriority._1);
  }


  public useLanguage(language: Language, priority: LanguageChangePriority) {
    if (priority < this.currentLangPriority) return;
    this.currentLangPriority = priority;

    let l = this.getLanguageToUse(language);
    console.log('useLanguage', l);
    this.translate.use(l);
    // Syncfusion
    setCulture(l);
    // /
    this.setStoredLanguage(language);

    this.document.documentElement.lang = l;

    // PrimeNG
    this.translate.get('primeng').subscribe((res) => {
      this.primeNGConfig.setTranslation(res);
    });
  }

  public getLanguageToUse(language: Language): Language {
    if (language === Language.CS || language === Language.SK) {
      return Language.CS;
    }
    return Language.EN;
  }

  public hasTranslation(translationKey: string, language?: string): boolean {
    const translations = this.translate.translations[language || this.translate.currentLang];
    return translations && translations[translationKey] !== undefined;
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
  }
}
